<template>
    <div>
        <LiefengContent>
            <template v-slot:title>{{ "社区架构初始化拆分" }} <span style="margin-left:20px;color:#00bfbf" >{{$route.query.name}}</span></template>
            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true">
                    
                    <Button :type="schedule>0? 'success' :''" :loading="table.clearLoading" style="margin-right: 0px" @click="clearData" >① 清理数据</Button>
                    <Icon type="md-play" :color="schedule>0? '#29BE6C':'#AAAAAA'"/>

                    <Button :type="schedule>1? 'success':''" :loading="table.splitLoading" style="margin-right: 0px" @click="splitData" :disabled="schedule>0?false:true">② 拆分</Button>
                    <Icon type="md-play" :color="schedule>1? '#29BE6C':'#AAAAAA'" />

                    <Button :type="schedule>2? 'success':''" :loading="table.btnLoading" style="margin-right: 10px" @click="reSetData" :disabled="schedule>1?false:true">③ 初始化到社区架构</Button>

                    <FormItem><span style="margin-right:10px;color:#999">注意：点击初始化后将提交所有数据，需在当前页全部修改正确后再点击初始化。</span></FormItem>
                    <Button type="primary" @click="exportExcel">导出</Button>
                </Form>
            </template>
            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="table.talbeColumns"
                    :tableData="table.tableData"
                    :loading="table.loading"
                    :fixTable="true"
                    :curPage="table.page"
                    :total="table.total"
                    :pagesizeOpts="[200,500,1000]"
                    :page-size="table.pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: { LiefengContent, LiefengTable },
    data() {
        return {
            search: {},
            table: {
                talbeColumns: [
                    {
                        key: "houseName",
                        align: "center",
                        title: "门牌号",
                        minWidth:200,
                    },
                    {
                        key: "address",
                        align: "center",
                        title: "清理后门牌号",
                        minWidth:240,
                        render: (h, params) => {
                            return h("div", [
                                h("Input", {
                                    props: {
                                        value: params.row.address,
                                    },
                                    on: {
                                        "on-change": e => {
                                            let sourceName = ''
                                            this.table.tableData.map(item => {
                                                if (item.id == params.row.id) {
                                                    sourceName = item.address
                                                }
                                            })
                                            clearTimeout(this.timer)
                                            this.timer = setTimeout( () => {
                                                this.$Message.loading({
                                                    content: `拆分 ${e.target.value} 中，请稍等...`,
                                                    duration: 0,
                                                });
                                                this.$post(`/syaa/pc/sy/user/gridManage/splitOneCleanAddress`,{
                                                    id:params.row.id,
                                                    address:e.target.value,
                                                    archivesId:params.row.archivesId
                                                }).then( res => {
                                                    this.$Message.destroy();
                                                    if(res.code == 200){
                                                        this.$Message.success({
                                                            content:`拆分${e.target.value}成功`,
                                                            background:true
                                                        })

                                                        this.table.tableData.map(item => {
                                                            if (item.id == params.row.id) {
                                                                item.address = res.data.address
                                                                item.roadName = res.data.roadName
                                                                item.projectName = res.data.projectName
                                                                item.buildingName = res.data.buildingName
                                                                item.houseAddr = res.data.houseAddr
                                                                item.houseSeq = res.data.houseSeq
                                                            }
                                                        })

                                                        // this.getList()

                                                    }else{
                                                        this.$Message.error({
                                                            content:`拆分${e.target.value}失败`,
                                                            background:true
                                                        })
                                                        this.table.tableData.map(item => {
                                                            if (item.id == params.row.id) {
                                                                item.address = sourceName
                                                            }
                                                        })
                                                    }
                                                })
                                            },1500)
                                        },
                                    },
                                }),
                            ])
                        },
                    },
                    {
                        key: "roadName",
                        align: "center",
                        width:200,
                        title: "街巷/路/经济社",
                        render: (h, params) => {
                            return h("div", [
                                h("Input", {
                                    props: {
                                        value: params.row.roadName,
                                    },
                                    on: {
                                        "on-change": e => {
                                            this.table.tableData.map(item => {
                                                if (item.id == params.row.id) {
                                                    item.roadName = e.target.value
                                                }
                                            })
                                        },
                                    },
                                }),
                            ])
                        },
                    },
                    {
                        key: "projectName",
                        align: "center",
                        title: "小区",
                        width:200,
                        render: (h, params) => {
                            return h("div", [
                                h("Input", {
                                    props: {
                                        value: params.row.projectName,
                                    },
                                    on: {
                                        "on-change": e => {
                                            this.table.tableData.map(item => {
                                                if (item.id == params.row.id) {
                                                    item.projectName = e.target.value
                                                }
                                            })
                                        },
                                    },
                                }),
                            ])
                        },
                    },
                    {
                        key: "buildingName",
                        align: "center",
                        title: "楼栋（多户）",
                        width:200,
                        render: (h, params) => {
                            return h("div", [
                                h("Input", {
                                    props: {
                                        value: params.row.buildingName,
                                    },
                                    on: {
                                        "on-change": e => {
                                            this.table.tableData.map(item => {
                                                if (item.id == params.row.id) {
                                                    item.buildingName = e.target.value
                                                }
                                            })
                                        },
                                    },
                                }),
                            ])
                        },
                    },
                    {
                        key: "houseAddr",
                        align: "center",
                        title: "房号",
                        width:140,
                        render: (h, params) => {
                            return h("div", [
                                h("Input", {
                                    props: {
                                        value: params.row.houseAddr,
                                    },
                                    on: {
                                        "on-change": e => {
                                            this.table.tableData.map(item => {
                                                if (item.id == params.row.id) {
                                                    item.houseAddr = e.target.value
                                                }
                                            })
                                        },
                                    },
                                }),
                            ])
                        },
                    },
                    {
                        key: "houseSeq",
                        align: "center",
                        title: "排序",
                        width:120,
                        render: (h, params) => {
                            return h("div", [
                                h("Input", {
                                    props: {
                                        value: params.row.houseSeq,
                                    },
                                    on: {
                                        "on-change": e => {
                                            this.table.tableData.map(item => {
                                                if (item.id == params.row.id) {
                                                    item.houseSeq = e.target.value
                                                }
                                            })
                                        },
                                    },
                                }),
                            ])
                        },
                    },
                    {
                        title: "操作",
                        align: "center",
                        width: 120,
                        render: (h, params) => {
                            return h("div", [
                                h(
                                    "Button",
                                    {
                                        props: {
                                            type: "error",
                                            size: "small",
                                        },
                                        nativeOn:{
                                          click:() =>{
                                              this.table.tableData.some((item,index)=>{
                                                if(item.id == params.row.id){
                                                  this.table.tableData.splice(index,1)
                                                }
                                              })
                                          }
                                        }
                                        
                                    },
                                    "删除"
                                ),
                            ])
                        },
                    },
                ],
                tableData: [],
                loading: false,
                page: 1,
                total: 0,
                pageSize: 200,
                currentPage: 1,
                btnLoading: false,
                clearLoading:false,
                splitLoading:false,


                
            },
            timer:'',
            schedule:0
        }
    },
    async created() {
      await this.getList()
    },
    methods: {
        // 切换分页事件
        hadlePageSize(val) {
            this.table.page = val.page
            this.table.pageSize = val.pageSize
            this.schedule = 0
            this.getList()
        },
        // 点击初始化到社区架构按钮
        reSetData() {
            let arr1 = JSON.parse(JSON.stringify(this.table.tableData))
            let arr2 = arr1.map( item => {
                return {
                    streetName:item.roadName,
                    projectName:item.projectName,
                    buildingName:item.buildingName,
                    houseNum:item.houseAddr,
                    addr:item.address,
                    seq:item.houseSeq,
                    // id:item.id
                }
            })
            this.updateList(arr2)
        },
        // 分页接口
    //    async getList() {
    //      this.table.loading = true
    //      try{
    //          await this.$post('/syaa/pc/sy/user/gridManage/openOrgNamePage',{
    //             archivesId:'',
    //             orgCode:this.$route.query.orgCode,
    //             page:this.table.page,
    //             pageSize:this.table.pageSize
    //         }).then(res=>{
    //             this.table.loading = false
    //             if(res && res.code == 200 ){
    //                 if(res.dataList && res.dataList.length){
    //                      res.dataList.map((item,index) =>{
    //                         item.seq = index + 1
    //                     })
    //                 }
    //                 this.table.tableData = res.dataList
    //                 this.table.total = this.table.tableData.length
    //                 this.table.currentPage = res.currentPage
    //             }else{
    //                 this.$Message.error({
    //                     content:'获取数据失败',
    //                     background:true
    //                 })
    //                 return
    //             }
    //         })
    //      }catch(err){
    //          this.table.loading = false
    //          this.$Message.error({
    //              content:'获取数据失败',
    //              background:true
    //          })
    //          return
    //      }
         
    //     },

        async getList() {
         this.table.loading = true
         try{
             await this.$get('/syaa/pc/sy/user/gridManage/getInitOrgByPage',{
                // archivesId:'',
                syncOrgNo:1,
                orgCode:this.$route.query.orgCode,
                page:this.table.page,
                pageSize:this.table.pageSize
            }).then(res=>{
                this.table.loading = false
                if(res && res.code == 200 ){
                    this.table.tableData = res.dataList
                    // this.table.tableData.map( item => {
                    //     if(item.address && this.schedule != 1 && this.schedule !=2)
                    //     this.schedule = 1
                    //     if((item.roadName || item.projectName || item.buildingName || item.houseAddr) && this.schedule !=2){
                    //         this.schedule = 2
                    //     }
                        
                    // })
                    this.table.total = res.maxCount
                    this.table.currentPage = res.currentPage
                    if (this.schedule != 1 && this.schedule != 2) this.schedule = 0
                }else{
                    this.$Message.error({
                        content:'获取数据失败',
                        background:true
                    })
                    return
                }
            })
         }catch(err){
             this.table.loading = false
             this.$Message.error({
                 content:'获取数据失败',
                 background:true
             })
             return
         }
         
        },
        // 同步接口
        updateList(val){
          this.table.btnLoading = true
          let name = this.$route.query.name.split('-')
          try{
            this.$post('/syaa/pc/sy/user/gridManage/syncOrg',{
              orgOpenVos:val,
              orgCode:parent.vue.loginInfo.userinfo.orgCode,
              communityCode:this.$route.query.orgCode,
              communityName:name[name.length - 1],
            },{"Context-Type":'Application/json'}).then(res=>{
            this.table.btnLoading = false
            if(res.code == 200){
              this.$Message.success({
                content:'初始化成功',
                background:true
              })
              this.table.page = 1
              this.table.pageSize = 200
              this.schedule = 3
              this.getList()
              return
            }else{
              this.$Message.error({
                content:'初始化失败',
                background:true
              })
              return
              
            }
          })
          }catch(err){
              this.table.btnLoading = false
              this.$Message.error({
                content:'初始化失败',
                background:true
              })
              return
          } 
          
        },

        // 导出操作
        exportExcel(){
            this.$Modal.confirm({
                title: "温馨提示",
                content:
                "<p>您正在导出当前页的数据，是否继续？</p>",
                onOk: () => {
                let tHeader = [
                    "清理后门牌号",
                    "街巷/路/经济社",
                    "小区",
                    "楼栋（多户）",
                    "房号",
                    "排序"
                ];
                let filterVal = [
                    "address",
                    "roadName",
                    "projectName",
                    "buildingName",
                    "houseAddr",
                    "houseSeq"
                ];
                let tableData = JSON.parse(JSON.stringify(this.table.tableData));
                this.$core.exportExcel(tHeader, filterVal, tableData, `${this.$route.query.name}社区架构初始化拆分`);
                },
            });
        },

        // 清理数据
        clearData(){
            if(this.table.tableData.length == 0){
                this.$Message.warning({
                    content:'当前暂无数据',
                    background:true,
                })
                return
            }
            let houseName = []
            this.table.tableData.map( item => {
                houseName.push(item.houseName)
            })
            try{
                this.table.clearLoading = true
                this.$post('/syaa/pc/sy/user/gridManage/cleanDataAddress',{
                    orgCode:this.$route.query.orgCode,
                    houseName:houseName.join(',')
                },{'Content-Type':'Application/json'}).then(res => {
                    if(res.code == 200){
                        this.$Message.success({
                            content:'清理数据成功',
                            background:true
                        })
                        this.schedule = 1
                        this.getList()
                        this.table.clearLoading = false
                    }else{
                        this.$Message.error({
                            content:'清理数据失败',
                            background:true
                        })
                        this.table.clearLoading = false
                        return
                    }
                })
            }catch{
                this.table.clearLoading = false
                this.$Message.error({
                    content:'清理数据失败',
                    background:true
                })
            }
            
        },

        // 拆分
        splitData(){
            let houseName = []
                this.table.tableData.map( item => {
                    houseName.push(item.houseName)
            })
            try{
                this.table.splitLoading = true
                this.$post('/syaa/pc/sy/user/gridManage/splitCleanAddress',{
                    orgCode:this.$route.query.orgCode,
                    houseName:houseName.join(',')
                },{'Content-Type':'Application/json'}).then(res => {
                    if(res.code == 200){
                        this.$Message.success({
                            content:'拆分数据成功',
                            background:true
                        })
                        this.schedule = 2
                        this.getList()
                        this.table.splitLoading = false
                    }else{
                        this.$Message.error({
                            content:'拆分数据失败',
                            background:true
                        })
                        this.table.splitLoading = false
                        return
                    }
                })
            }catch{
                this.table.splitLoading = false
                this.$Message.error({
                    content:'拆分数据失败',
                    background:true
                })
            }
        },
    },
}
</script>
